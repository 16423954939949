import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-west-jordan-utah.png'
import image0 from "../../images/cities/scale-model-of-the-farm-at-gardner-village-in-west-jordan-utah.png"
import image1 from "../../images/cities/scale-model-of-west-jordan-off-leash-dog-park-in-west-jordan-utah.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='West Jordan'
            state='Utah'
            image={image}
            lat='40.6096698'
            lon='-111.93910310000001'
            attractions={ [{"name": "The Farm at Gardner Village", "vicinity": "1100 W 7800 S, West Jordan", "types": ["point_of_interest", "establishment"], "lat": 40.6093584, "lng": -111.92436470000001}, {"name": "West Jordan Off-Leash Dog Park", "vicinity": "5982 New Bingham Hwy, West Jordan", "types": ["park", "point_of_interest", "establishment"], "lat": 40.5910679, "lng": -112.03217749999999}] }
            attractionImages={ {"The Farm at Gardner Village":image0,"West Jordan Off-Leash Dog Park":image1,} }
       />);
  }
}